html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  background: #F9FAFB;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0px;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  color: inherit;
}

* {
  box-sizing: border-box !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: normal;
}

.background{
  position: relative;
}

button{
  cursor: pointer;
}

.overflow-y{
  overflow-y: scroll;
}
.overflow-y::-webkit-scrollbar {
  display: none;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

a *{
  cursor: pointer;
}
